import { useNavigate, useParams } from 'react-router-dom';

import { useCheckFlag } from '@speeki/feature-flags';

import { CardDataRow } from '@components/atoms/CardDataRow/CardDataRow';

import { ClientDetailsDocument } from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { internalPaths } from '@shared/paths';

interface PrimaryContactProps {
    openModal: () => void;
}

export const PrimaryContact = ({ openModal }: PrimaryContactProps) => {
    const navigate = useNavigate();
    const isTaskManagerEnabled = useCheckFlag(
        'client_admin-task_manager-20240318',
    );
    const { clientId } = useParams<{ clientId: string }>();
    const { data } = useQuery(ClientDetailsDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => navigate(internalPaths.clients),
        variables: {
            disableTaskManager: !isTaskManagerEnabled,
            tenantUuid: clientId || '',
        },
    });

    const primaryContact = data?.clientDetails.primaryContact;
    const fullName = data?.clientDetails
        ? primaryContact?.firstName + ' ' + primaryContact?.lastName
        : '';

    return (
        <>
            <Card.Header gap={'16px'} marginBottom={'16px'}>
                <Card.SectionName>Primary contact</Card.SectionName>
                <Card.EditButton
                    icon={'SvgEdit24'}
                    iconProps={{ onClick: openModal }}
                    size={24}
                    themeColors={{
                        default: { 'color-1': 'main.tertiary' },
                    }}
                />
            </Card.Header>
            <Card.CardData>
                <CardDataRow name={'Full name:'} value={fullName} />
                <CardDataRow
                    name={'Email address:'}
                    value={primaryContact?.email}
                />
            </Card.CardData>
        </>
    );
};
