import { useParams } from 'react-router-dom';

import { useCheckFlag } from '@speeki/feature-flags';
import { useModal } from '@speeki/react-hooks';

import { ClientDetailsDocument } from '@graphql/generated/graphql';

import { useQuery } from '@apollo/client';
import { Card } from '@domains/clients/ions/Card.styled';
import { CompanyInformation } from '@domains/clients/organisms/CompanyInformation/CompanyInformation';
import { CompanyInformationModal } from '@domains/clients/organisms/CompanyInformationModal/CompanyInformationModal';
import { PrimaryContact } from '@domains/clients/organisms/PrimaryContact/PrimaryContact';
import { PrimaryContactModal } from '@domains/clients/organisms/PrimaryContactModal/PrimaryContactModal';
import { SubscriptionInformation } from '@domains/clients/organisms/SubscriptionInformation/SubscriptionInformation';
import { SubscriptionInformationModal } from '@domains/clients/organisms/SubscriptionInformationModal/SubscriptionInformationModal';
import { CardsWrapper } from '@domains/clients/pages/ClientsPage/ClientsPage.styled';

export const DetailsTab = () => {
    const isTaskManagerEnabled = useCheckFlag(
        'client_admin-task_manager-20240318',
    );
    const { clientId } = useParams<{ clientId: string }>();
    const { data, loading } = useQuery(ClientDetailsDocument, {
        variables: {
            disableTaskManager: !isTaskManagerEnabled,
            tenantUuid: clientId || '',
        },
    });

    const companyInformationModal = useModal();
    const primaryContactModal = useModal();
    const subscriptionInformationModal = useModal();

    return (
        <CardsWrapper>
            <Card.Wrapper>
                <Card.InnerWrapper>
                    <CompanyInformation
                        openModal={() => companyInformationModal.setOpen(true)}
                    />
                    <Card.Row />
                    <PrimaryContact
                        openModal={() => primaryContactModal.setOpen(true)}
                    />
                    <Card.Row />
                    <SubscriptionInformation
                        openModal={() =>
                            subscriptionInformationModal.setOpen(true)
                        }
                    />
                </Card.InnerWrapper>
            </Card.Wrapper>

            {!loading && data && companyInformationModal.open && (
                <CompanyInformationModal
                    {...companyInformationModal}
                    defaultData={{
                        id: data.clientDetails.uuid,
                        companyName: data.clientDetails.name,
                        country: data.clientDetails.country,
                    }}
                    tenantUuid={data.clientDetails.uuid}
                />
            )}

            {!loading && data && primaryContactModal.open && (
                <PrimaryContactModal
                    {...primaryContactModal}
                    defaultData={{
                        id: data.clientDetails.uuid,
                        email: data.clientDetails.primaryContact.email ?? '',
                        firstName:
                            data.clientDetails.primaryContact.firstName || '',
                        lastName:
                            data.clientDetails.primaryContact.lastName || '',
                    }}
                    tenantUuid={data.clientDetails.uuid}
                />
            )}
            {!loading && data && subscriptionInformationModal.open && (
                <SubscriptionInformationModal
                    {...subscriptionInformationModal}
                    defaultData={{
                        id: data.clientDetails.uuid,
                        limitedLicenses:
                            data.clientDetails.subscription.limitedLicenseCount,
                        plan: data.clientDetails.subscription.plan?.name || '',
                        standardLicenses:
                            data.clientDetails.subscription
                                .standardLicenseCount,
                        subscriberEmail:
                            data.clientDetails.subscription.subscriberEmail,
                        subscriptionEnd: data.clientDetails.subscription.end,
                        subscriptionStart:
                            data.clientDetails.subscription.start,
                    }}
                    tenantUuid={data.clientDetails.uuid}
                />
            )}
        </CardsWrapper>
    );
};
