import { useCheckFlag } from '@speeki/feature-flags';

import { DeepPartial } from '@@types/CommonTypes';

import {
    ClientDetailsDocument,
    CustomPlanUpdateInput,
    CustomUpdatePlanDocument,
    Feature,
    ServiceModules,
    Subscription_Plan,
} from '@graphql/generated/graphql';

import { toCamelCase, toSnakeUpperCase } from '@utils/helpers';

import { MutationResult, useMutation } from '@apollo/client';
import { client } from '@boot/apolloClient';

export const useCustomUpdatePlan = (
    clientId: string,
    options?: {
        onSuccess?: () => void;
    },
): [(input: DeepPartial<CustomPlanUpdateInput>) => void, MutationResult] => {
    const [mutationAction, result] = useMutation(CustomUpdatePlanDocument, {});
    const isTaskManagerEnabled = useCheckFlag(
        'client_admin-task_manager-20240318',
    );

    const updatePlan = (input: DeepPartial<CustomPlanUpdateInput>) => {
        const data = client.readQuery({
            query: ClientDetailsDocument,
            variables: {
                disableTaskManager: !isTaskManagerEnabled,
                tenantUuid: clientId,
            },
        });

        const currentSubscription = data?.clientDetails.subscription;
        const currentPlan = currentSubscription?.plan;
        const currentModules = currentPlan?.modules;
        const features = currentPlan?.features.reduce(
            (prevValue, currentValue) => {
                const name = toCamelCase(currentValue.name);

                return {
                    ...prevValue,
                    [name]: currentValue.enabled ?? false,
                };
            },
            {},
        ) as CustomPlanUpdateInput['features'];

        const currentPlanSettings: CustomPlanUpdateInput = {
            cases: currentModules?.cases.enabled ?? false,
            demo: currentModules?.demo.enabled ?? false,
            disclosures: currentModules?.disclosures.enabled ?? false,
            esg: currentModules?.esg.enabled ?? false,
            features,
            sso: currentModules?.sso.enabled ?? false,
            ...(isTaskManagerEnabled
                ? {
                      tasks: currentModules?.tasks?.enabled ?? false,
                  }
                : {}),
            subsidiarySites: currentModules?.subsidiarySites.enabled ?? false,
            surveys: currentModules?.surveys.enabled ?? false,
            teams: currentModules?.teams.enabled ?? false,
            tenantUuid: clientId,
            trainings: false,
        };

        return mutationAction({
            context: {
                notifyOnError: true,
            },
            onCompleted: () => {
                options?.onSuccess?.();
            },
            onError: () => {},
            optimisticResponse: ({ input }) => {
                const caseFeatures =
                    input.features &&
                    Object.entries(input.features).map(([key, value]) => ({
                        uuid:
                            currentModules?.cases.features.find(
                                (feature) =>
                                    feature.name === toSnakeUpperCase(key),
                            )?.uuid || '',
                        enabled: value ?? false,
                        name: toSnakeUpperCase(key) as Feature,
                    }));

                return {
                    clientDetails: {
                        uuid: clientId,
                        __typename: 'AdminTenantData',
                        subscription: {
                            __typename: 'AdminTenantSubscription',
                            end: currentSubscription?.end,
                            limitedLicenseCount:
                                currentSubscription?.limitedLicenseCount || 0,
                            plan: {
                                uuid: input.tenantUuid,
                                __typename: 'SubscriptionPlan',
                                features: currentPlan?.features || [],
                                modules: {
                                    cases: {
                                        uuid: currentModules?.cases.uuid ?? '',
                                        enabled: input.cases ?? false,
                                        features: caseFeatures ?? [],
                                        name: 'CASES',
                                    },
                                    demo: {
                                        uuid: currentModules?.demo.uuid ?? '',
                                        enabled: input.demo ?? false,
                                        features:
                                            currentModules?.demo.features ?? [],
                                        name: 'DEMO',
                                    },
                                    disclosures: {
                                        uuid:
                                            currentModules?.disclosures.uuid ??
                                            '',
                                        enabled: input.disclosures ?? false,
                                        features:
                                            currentModules?.disclosures
                                                .features ?? [],
                                        name: 'DISCLOSURES',
                                    },
                                    esg: {
                                        uuid: currentModules?.esg.uuid ?? '',
                                        enabled: input.esg ?? false,
                                        features:
                                            currentModules?.esg.features ?? [],
                                        name: 'ESG',
                                    },
                                    sso: {
                                        uuid: currentModules?.sso.uuid ?? '',
                                        enabled: input.sso ?? false,
                                        features:
                                            currentModules?.sso.features ?? [],
                                        name: 'SSO',
                                    },
                                    subsidiarySites: {
                                        uuid:
                                            currentModules?.subsidiarySites
                                                .uuid ?? '',
                                        enabled: input.subsidiarySites ?? false,
                                        features:
                                            currentModules?.subsidiarySites
                                                .features ?? [],
                                        name: 'SUBSIDIARY_SITES',
                                    },
                                    surveys: {
                                        uuid:
                                            currentModules?.surveys.uuid ?? '',
                                        enabled: input.surveys ?? false,
                                        features:
                                            currentModules?.surveys.features ??
                                            [],
                                        name: 'SURVEYS',
                                    },

                                    ...(isTaskManagerEnabled
                                        ? {
                                              tasks: {
                                                  uuid:
                                                      currentModules?.tasks
                                                          ?.uuid ?? '',
                                                  enabled: input.tasks ?? false,
                                                  features:
                                                      currentModules?.tasks
                                                          ?.features ?? [],
                                                  name: 'TASKS' as ServiceModules,
                                              },
                                          }
                                        : {}),
                                    teams: {
                                        uuid: currentModules?.teams.uuid ?? '',
                                        enabled: input.teams ?? false,
                                        features:
                                            currentModules?.teams.features ??
                                            [],
                                        name: 'TEAMS',
                                    },
                                },
                                name: currentPlan?.name as Subscription_Plan,
                            },
                            standardLicenseCount:
                                currentSubscription?.standardLicenseCount || 0,
                            start: currentSubscription?.start,
                            status: currentSubscription?.status ?? 'ACTIVE',
                            subscriberEmail:
                                currentSubscription?.subscriberEmail,
                            toBeDeletedAt: currentSubscription?.toBeDeletedAt,
                        },
                    },
                };
            },
            variables: {
                disableTaskManager: !isTaskManagerEnabled,
                input: {
                    ...currentPlanSettings,
                    ...input,
                    features: {
                        casesAssignmentRules: false,
                        casesManualCreate: false,
                        casesPrinting: false,
                        casesRegionalRole: false,
                        casesReminder: false,
                        ...currentPlanSettings.features,
                        ...input.features,
                    },
                },
            },
        });
    };

    return [updatePlan, result];
};
