import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCheckFlag } from '@speeki/feature-flags';
import { notify } from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import {
    ChangeTenantStatusDocument,
    ClientDetailsDocument,
    TenantDeletionDocument,
} from '@graphql/generated/graphql';

import { useCustomUpdatePlan } from '@utils/hooks/useCustomUpdatePlan';

import { useMutation, useQuery } from '@apollo/client';
import { INFO_MESSAGES, SUCCESS_MESSAGES } from '@shared/notifyMessages';
import { internalPaths } from '@shared/paths';

type TabName = 'Details' | 'Microservices' | 'Entities';

export interface ClientTableTab {
    disabled?: boolean;
    name: TabName;
    path: string;
}

export const useSingleClientPage = () => {
    const navigate = useNavigate();
    const { clientId } = useParams<{ clientId: string }>();
    const reactivationConfirmationModal = useModal();
    const deactivationConfirmationModal = useModal();
    const deleteConfirmationModal = useModal();
    const isTaskManagerEnabled = useCheckFlag(
        'client_admin-task_manager-20240318',
    );

    const [clientTableTab, setClientTableTab] = useState<ClientTableTab[]>([
        {
            name: 'Details',
            path: internalPaths.clientDetails(clientId),
        },
        {
            name: 'Microservices',
            path: internalPaths.clientMicroservices(clientId),
        },
    ]);

    const { data } = useQuery(ClientDetailsDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => navigate(internalPaths.clients),
        variables: {
            disableTaskManager: !isTaskManagerEnabled,
            tenantUuid: clientId || '',
        },
    });

    const tenantName = data?.clientDetails?.name ?? '';
    const tenantDeletionDate = dayjs(
        data?.clientDetails?.subscription.toBeDeletedAt,
    ).format('YYYY/MM/DD HH:mm');

    const [updatePlan, { loading: loadingUpdatePlan }] = useCustomUpdatePlan(
        clientId || '',
    );

    const [changeTenantStatus, { loading: loadingChange }] = useMutation(
        ChangeTenantStatusDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () =>
                notify(
                    SUCCESS_MESSAGES.TENANT_REACTIVATION(tenantName),
                ).SUCCESS(),
        },
    );

    const [deleteTenant, { loading: loadingDelete }] = useMutation(
        TenantDeletionDocument,
        {
            context: { notifyOnError: true },
            onCompleted: () => {
                notify(INFO_MESSAGES.TENANT_DELETION(tenantName)).INFO();
                navigate(internalPaths.clients);
            },
        },
    );

    const isTenantDeactivated =
        data?.clientDetails.subscription.status === 'INACTIVE';

    const subsidiarySitesEnabled =
        !!data?.clientDetails.subscription.plan?.modules.subsidiarySites
            .enabled;
    const demoModule = data?.clientDetails.subscription.plan?.modules.demo;
    const isTenantAwaitActivation =
        data?.clientDetails.subscription.status === 'AWAIT_ACTIVATION';

    const handleUpdateDemoMode = () => {
        !loadingUpdatePlan &&
            updatePlan({
                demo: !demoModule?.enabled,
            });
    };

    const handleReactivateTenant = () =>
        void changeTenantStatus({
            variables: {
                disableTaskManager: !isTaskManagerEnabled,
                input: { isActive: true, tenantUuid: clientId ?? '' },
            },
        });

    const handleDeleteTenant = () =>
        void deleteTenant({
            variables: { input: { tenantName, tenantUuid: clientId ?? '' } },
        });

    useEffect(() => {
        let currentTabs = [...clientTableTab];
        const subsidiaryExists = currentTabs.find(
            (tab) => tab.name === 'Entities',
        );

        if (subsidiarySitesEnabled && !subsidiaryExists)
            currentTabs.push({
                name: 'Entities',
                path: internalPaths.clientSubsidiarySites(clientId),
            });
        else currentTabs = currentTabs.filter((tab) => tab.name !== 'Entities');

        setClientTableTab(currentTabs);
    }, [subsidiarySitesEnabled]);

    return {
        clientId,
        clientTableTab,
        data,
        deactivationConfirmationModal,
        deleteConfirmationModal,
        demoModule,
        handleDeleteTenant,
        handleReactivateTenant,
        handleUpdateDemoMode,
        isTenantAwaitActivation,
        isTenantDeactivated,
        loadingChange,
        loadingDelete,
        reactivationConfirmationModal,
        tenantDeletionDate,
    };
};
