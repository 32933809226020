import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ESG_SUBCATEGORY, FEATURE_LABELED } from '@speeki/dictionary';
import { useCheckFlag } from '@speeki/feature-flags';
import { CardList, CardListData, Switch } from '@speeki/global-ui-components';
import { useModal } from '@speeki/react-hooks';

import { CardDataRow } from '@components/atoms/CardDataRow/CardDataRow';

import {
    ClientDetailsDocument,
    Esg_Subcategory,
    StandardStatusDocument,
} from '@graphql/generated/graphql';

import { sortArray, toCamelCase } from '@utils/helpers';
import { useCustomUpdatePlan } from '@utils/hooks/useCustomUpdatePlan';

import { useMutation, useQuery } from '@apollo/client';
import { client } from '@boot/apolloClient';
import { Card } from '@domains/clients/ions/Card.styled';
import { DisableCustomStandardModal } from '@domains/clients/organisms/DisableCustomStandardModal/DisableCustomStandardModal';
import { DisableSubsidiarySiteModal } from '@domains/clients/organisms/DisableSubsidiarySiteModal/DisableSubsidiarySiteModal';
import { ELineNumbersCard } from '@domains/clients/organisms/ELineNumbers/ELineNumbersCard/ELineNumbersCard';
import { WatsonCredentialsModal } from '@domains/clients/organisms/WatsonCredentialsModal/WatsonCredentialsModal';
import { CardsWrapper } from '@domains/clients/pages/ClientsPage/ClientsPage.styled';
import { internalPaths } from '@shared/paths';

export const MicroservicesTab = () => {
    const navigate = useNavigate();
    const isTaskManagerEnabled = useCheckFlag(
        'client_admin-task_manager-20240318',
    );
    const { clientId } = useParams<{ clientId: string }>();
    const [customStandardUuid, setCustomStandardUuid] = useState<
        string | undefined
    >();
    const { data, loading } = useQuery(ClientDetailsDocument, {
        context: {
            notifyOnError: true,
        },
        onError: () => navigate(internalPaths.clients),
        variables: {
            disableTaskManager: !isTaskManagerEnabled,
            tenantUuid: clientId || '',
        },
    });

    const credentialsModal = useModal();
    const subsidiarySiteModal = useModal();
    const disablingCustomStandardModal = useModal();

    const [updatePlan, { loading: loadingUpdatePlan }] = useCustomUpdatePlan(
        clientId || '',
        {
            onSuccess: subsidiarySiteModal.open
                ? () => subsidiarySiteModal.setOpen(false)
                : undefined,
        },
    );

    const [updateStandards, { loading: loadingUpdateStandards }] = useMutation(
        StandardStatusDocument,
        {
            context: {
                notifyOnError: true,
            },
            onError: () => {},
            optimisticResponse: ({
                input: { tenantStandards, tenantUuid },
            }) => {
                const currentStandards = client.readQuery({
                    query: ClientDetailsDocument,
                    variables: {
                        disableTaskManager: isTaskManagerEnabled,
                        tenantUuid: tenantUuid,
                    },
                })?.clientDetails.standards;

                const optimisticStandards = tenantStandards.map((standard) => ({
                    uuid: standard.uuid,
                    __typename: 'EsgStandard' as const,
                    enabled: standard.status,
                    name:
                        currentStandards?.find(
                            (currentStandard) =>
                                currentStandard.uuid === standard.uuid,
                        )?.name ?? '',
                }));

                return {
                    standardStatus: {
                        uuid: tenantUuid,
                        __typename: 'EsgStandard',
                        standards: optimisticStandards,
                    },
                };
            },
        },
    );

    const assistantCredentials = data?.clientDetails.assistantCredentials;
    const currentStandards =
        data?.clientDetails.standards?.map((standard) => ({
            id: standard.uuid,
            enabled: standard.enabled,
            label: ESG_SUBCATEGORY[standard.name as Esg_Subcategory],
        })) ?? [];

    const {
        cases,
        disclosures,
        esg,
        sso,
        subsidiarySites,
        surveys,
        tasks,
        teams,
    } = data?.clientDetails.subscription.plan?.modules || {};

    const casesCustomFeatures: CardListData[] =
        cases?.features
            ?.map((feature) => {
                return {
                    id: toCamelCase(feature.name),
                    enabled: feature.enabled,
                    label: FEATURE_LABELED[feature.name],
                };
            })
            .sort((a, b) => sortArray(a.label, b.label)) || [];

    const handleSubsidiarySiteDisable = () => {
        !loadingUpdatePlan &&
            updatePlan({
                subsidiarySites: !subsidiarySites?.enabled,
            });
    };

    return (
        <>
            <CardsWrapper>
                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.SectionName>
                                Please note that, any change on the
                                Microservices tab changes the current plan to
                                CUSTOM.
                            </Card.SectionName>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={true}>
                                <Switch
                                    checked={!!cases?.enabled}
                                    onChange={() =>
                                        !loadingUpdatePlan &&
                                        updatePlan({
                                            cases: !cases?.enabled,
                                        })
                                    }
                                    testId="kaGDyTR"
                                />
                                <Card.CardName>Incidents</Card.CardName>
                            </Card.AccordionWrapper>
                            <Card.Row />
                        </Card.Header>
                        <Card.Header marginBottom={'16px'}>
                            <Card.SectionName>
                                Custom permissions
                            </Card.SectionName>
                        </Card.Header>
                        <Card.CardData>
                            <CardList
                                data={casesCustomFeatures}
                                mode={'column'}
                                onSwitch={(props) => {
                                    !loadingUpdatePlan &&
                                        updatePlan({
                                            features: {
                                                [props.id]: props.enabled,
                                            },
                                        });
                                }}
                                testId="9mSSi3V"
                            />
                        </Card.CardData>
                        <Card.Row small />

                        <Card.Header gap={'16px'} marginBottom={'16px'}>
                            <Card.SectionName>
                                Watson credentials
                            </Card.SectionName>
                            <Card.EditButton
                                icon={'SvgEdit24'}
                                iconProps={{
                                    onClick: () =>
                                        credentialsModal.setOpen(true),
                                }}
                                size={24}
                                themeColors={{
                                    default: { 'color-1': 'main.tertiary' },
                                }}
                            />
                        </Card.Header>
                        <Card.CardData>
                            <CardDataRow
                                name={'Api key:'}
                                value={assistantCredentials?.apiKey}
                            />
                            <CardDataRow
                                name={'Instance url:'}
                                value={assistantCredentials?.instanceUrl}
                            />
                            <CardDataRow
                                name={'Assistant ID:'}
                                value={assistantCredentials?.assistantId}
                            />
                            <CardDataRow
                                name={'Workspace name:'}
                                value={assistantCredentials?.workspaceName}
                            />
                            <CardDataRow
                                name={'Workspace ID:'}
                                value={assistantCredentials?.workspaceId}
                            />
                        </Card.CardData>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <ELineNumbersCard />

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!subsidiarySites?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                        subsidiarySites?.enabled
                                            ? subsidiarySiteModal.setOpen(true)
                                            : updatePlan({
                                                  subsidiarySites:
                                                      !subsidiarySites?.enabled,
                                              });
                                    }}
                                    testId="hfHFQmw"
                                />
                                <Card.CardName>Manage entities</Card.CardName>
                            </Card.AccordionWrapper>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!surveys?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                            updatePlan({
                                                surveys: !surveys?.enabled,
                                            });
                                    }}
                                    testId="6UuSBJO"
                                />

                                <Card.CardName>Survey</Card.CardName>
                            </Card.AccordionWrapper>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!disclosures?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                            updatePlan({
                                                disclosures:
                                                    !disclosures?.enabled,
                                            });
                                    }}
                                    testId="vd5kOgc"
                                />
                                <Card.CardName>
                                    Declare and disclosure
                                </Card.CardName>
                            </Card.AccordionWrapper>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                {isTaskManagerEnabled && (
                    <Card.Wrapper>
                        <Card.InnerWrapper>
                            <Card.Header>
                                <Card.AccordionWrapper isExpanded={false}>
                                    <Switch
                                        checked={!!tasks?.enabled}
                                        onChange={() => {
                                            !loadingUpdatePlan &&
                                                updatePlan({
                                                    tasks: !tasks?.enabled,
                                                });
                                        }}
                                        testId="vd5kOgc"
                                    />
                                    <Card.CardName>Task manager</Card.CardName>
                                </Card.AccordionWrapper>
                            </Card.Header>
                        </Card.InnerWrapper>
                    </Card.Wrapper>
                )}

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!esg?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                            updatePlan({
                                                esg: !esg?.enabled,
                                            });
                                    }}
                                    testId="u5fO-j8"
                                />
                                <Card.CardName>ESG</Card.CardName>
                            </Card.AccordionWrapper>
                            <Card.Row />
                        </Card.Header>
                        <Card.Header marginBottom={'16px'}>
                            <Card.SectionName>
                                Custom standards
                            </Card.SectionName>
                        </Card.Header>
                        <Card.CardData>
                            <CardList
                                data={currentStandards}
                                mode={'column'}
                                onSwitch={(props) => {
                                    if (!loadingUpdateStandards) {
                                        if (!!props.enabled)
                                            void updateStandards({
                                                variables: {
                                                    input: {
                                                        tenantStandards: [
                                                            {
                                                                uuid: props.id,
                                                                status: props.enabled,
                                                            },
                                                        ],
                                                        tenantUuid:
                                                            clientId ?? '',
                                                    },
                                                },
                                            });
                                        else {
                                            setCustomStandardUuid(props.id);
                                            disablingCustomStandardModal.setOpen(
                                                true,
                                            );
                                        }
                                    }
                                }}
                                testId="8Ecief-"
                            />
                        </Card.CardData>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!teams?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                            updatePlan({
                                                teams: !teams?.enabled,
                                            });
                                    }}
                                    testId="RgcM-Vu"
                                />
                                <Card.CardName>MS Teams</Card.CardName>
                            </Card.AccordionWrapper>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                <Card.Wrapper>
                    <Card.InnerWrapper>
                        <Card.Header>
                            <Card.AccordionWrapper isExpanded={false}>
                                <Switch
                                    checked={!!sso?.enabled}
                                    onChange={() => {
                                        !loadingUpdatePlan &&
                                            updatePlan({
                                                sso: !sso?.enabled,
                                            });
                                    }}
                                    testId="ptovkpB"
                                />
                                <Card.CardName>SSO</Card.CardName>
                            </Card.AccordionWrapper>
                        </Card.Header>
                    </Card.InnerWrapper>
                </Card.Wrapper>

                {!loading && data && credentialsModal.open && (
                    <WatsonCredentialsModal
                        {...credentialsModal}
                        defaultData={assistantCredentials}
                        tenantUuid={data.clientDetails.uuid}
                    />
                )}

                {subsidiarySiteModal.open && (
                    <DisableSubsidiarySiteModal
                        {...subsidiarySiteModal}
                        disableModule
                        onConfirm={handleSubsidiarySiteDisable}
                    />
                )}

                {disablingCustomStandardModal.open && customStandardUuid && (
                    <DisableCustomStandardModal
                        {...disablingCustomStandardModal}
                        onClose={() => setCustomStandardUuid(undefined)}
                        onConfirm={() =>
                            updateStandards({
                                variables: {
                                    input: {
                                        tenantStandards: [
                                            {
                                                uuid: customStandardUuid,
                                                status: false,
                                            },
                                        ],
                                        tenantUuid: clientId ?? '',
                                    },
                                },
                            })
                        }
                    />
                )}
            </CardsWrapper>
        </>
    );
};
