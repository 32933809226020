import { useNavigate } from 'react-router-dom';

import { checkFlag } from '@speeki/feature-flags';
import { Button } from '@speeki/global-ui-components';

import {
    MainPageContent,
    MainPageName,
    MainPagePanel,
    MainPageWrapper,
} from '@components/ions/Pages.styled';
import { ClientsTable } from '@components/organisms/ClientsTable/ClientsTable';
import { MainLayout } from '@components/templates/MainLayout/MainLayout';

import { internalPaths } from '@shared/paths';

export const ClientsPage = () => {
    const navigate = useNavigate();

    return (
        <MainLayout>
            <MainPageWrapper>
                <MainPagePanel>
                    <MainPageName>Clients</MainPageName>
                    {checkFlag('speeki_admin-client_sign_up-20240105') && (
                        <Button
                            onClick={() =>
                                navigate(internalPaths.validationClient)
                            }
                            testId="lzSPCKl"
                            variant={'primary'}
                        >
                            Add new client
                        </Button>
                    )}
                </MainPagePanel>
                <MainPageContent>
                    <ClientsTable />
                </MainPageContent>
            </MainPageWrapper>
        </MainLayout>
    );
};
